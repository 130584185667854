import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaFacebookF, FaGoogle, FaMicrosoft, FaAmazon, FaApple} from 'react-icons/fa';
import './App.css';

function App() {
  // State variables for form handling
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state
    setLoading(true); // Set loading state

    try {
      const response = await fetch('https://api-test.virtuai.me/resume-enhancer/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'An error occurred. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Helmet for SEO and fonts */}
      <Helmet>
        <title>Resume Enhancer - Tailor Your Resume Effortlessly</title>
        <meta
          name="description"
          content="Optimize your resume to match specific job requirements. Join our waitlist for early access!"
        />
        <meta
          name="keywords"
          content="resume, job application, resume enhancer, job matching"
        />
        <meta name="author" content="Virtu AI" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Resume Enhancer - Tailor Your Resume Effortlessly" />
        <meta
          property="og:description"
          content="Optimize your resume to match specific job requirements. Join our waitlist for early access!"
        />
        <meta property="og:image" content="link-to-your-image.jpg" />
        <meta property="og:url" content="https://yourwebsite.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Resume Enhancer - Tailor Your Resume Effortlessly" />
        <meta
          name="twitter:description"
          content="Optimize your resume to match specific job requirements. Join our waitlist for early access!"
        />
        <meta name="twitter:image" content="link-to-your-image.jpg" />

        {/* Import Font from Google Fonts */}
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {/* Combined Hero Section */}
      <section className="hero">
        <div className="hero-inner">
          <div className="hero-content">
            <h2>Optimize Your Job Search</h2>
            <p>
              Applying to countless jobs is exhausting. Crafting a tailored resume for each position is impractical.{' '}
              <strong>We've built over 10,000 resumes.</strong> Let us do it for you—just provide your original resume and job links!
            </p>
          </div>
          <div className="hero-steps">
            <ol className="steps-list">
              <li>
                <h3>Analyze Your Resume</h3>
                <p>Upload your existing resume for our system to review.</p>
              </li>
              <li>
                <h3>Understand Job Requirements</h3>
                <p>Provide the job links, and we'll extract the key requirements.</p>
              </li>
              <li>
                <h3>Generate Tailored Resumes</h3>
                <p>Receive tailored resumes that match the jobs you're applying for.</p>
              </li>
            </ol>
          </div>
        </div>
      </section>

      {/* Waitlist section */}
      <section className="waitlist">
        <h2>Join Our Waitlist</h2>
        <p>
          Due to high demand, we're operating on a waitlist basis. Sign up to get early access!
        </p>

        {submitted ? (
          <p className="success-message">Thank you for joining the waitlist!</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br />
            <button type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Join Waitlist'}
            </button>
          </form>
        )}

        {error && <p className="error-message">{error}</p>}
      </section>

      {/* Features section */}
      <section className="features">
        <h2>Our Features</h2>
        <div className="feature-container">
          <div className="feature">
            <h3>Job Analysis</h3>
            <p>We read and understand job postings to extract essential requirements.</p>
          </div>
          <div className="feature">
            <h3>Resume Customization</h3>
            <p>Adjust your existing resume to highlight relevant skills and experiences.</p>
          </div>
          <div className="feature">
            <h3>Priority Skills</h3>
            <p>Identify and emphasize "as-a-plus" qualifications to stand out.</p>
          </div>
        </div>
      </section>

      {/* Trusted by Professionals section */}
      <section className="trusted">
        <h2>Trusted by Professionals from Leading Tech Companies</h2>
        <div className="logos">
          <div className="logo-item">
            <FaFacebookF />
          </div>
          <div className="logo-item">
            <FaGoogle />
          </div>
          <div className="logo-item">
            <FaMicrosoft />
          </div>
          {/* Add more icons as needed */}

          <div className="logo-item">
            <FaAmazon />
          </div>
          <div className="logo-item">
            <FaApple />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Virtu AI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
